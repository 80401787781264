import React, { useEffect } from 'react'
import styled from 'styled-components'
import { H1 } from "src/components"  
import { SEO } from "src/components/utils"  
import range from "lodash/range"


const NotFoundRoot = styled.div`
    height: 100vh;
    width: 100vw;
    ${props => props.theme.css.flexCenter()}
    background: linear-gradient(to top, ${props => props.theme.secondary}, ${props => props.theme.secondaryLight});
    text-align: center;

    ${H1} {
        font-weight: 400;
    }
`

const JumpingText = styled.span`
    position: relative;
    font-size: ${props => props.fontSize}px;
    color: white;
    top: 10px;
    text-shadow: 
        0 1px 0 #CCC,
        0 2px 0 #CCC,
        0 3px 0 #CCC,
        0 4px 0 #CCC,
        0 5px 0 #CCC,
        0 6px 0 transparent,
        0 7px 0 transparent,
        0 8px 0 transparent,
        0 9px 0 transparent,
        0 10px 10px rgba(0, 0, 0, .4);

    animation: bounce 0.3s ease infinite alternate ${props => props.delay}s;
    @keyframes bounce {
        100% {
            top: -10px;
            text-shadow: 
                0 1px 0 #CCC,
                0 2px 0 #CCC,
                0 3px 0 #CCC,
                0 4px 0 #CCC,
                0 5px 0 #CCC,
                0 6px 0 #CCC,
                0 7px 0 #CCC,
                0 8px 0 #CCC,
                0 9px 0 #CCC,
                0 50px 25px rgba(0, 0, 0, .2);
        }
    }
`

export default function NotFound() {
    // seo
    const seo = <SEO title="404 Not Found" description="404 Not Found" noindex/>


    useEffect(() => {
        window.scroll({ top: 0 })
    }, [])

    const
    statusCode = "404",
    statusCodeRender = range(0, statusCode.length).map(i => <JumpingText key={i} fontSize={100} delay={3 + i*(0.1)}>{statusCode[i]}</JumpingText>)
    const
    statusText = "Not Found",
    statusTextRender = range(0, statusText.length).map(i => <JumpingText key={i} fontSize={50} delay={4 + i*(0.1)}>{statusText[i]}</JumpingText>)
    

    return <NotFoundRoot>
        {seo}
        <H1>
            {statusCodeRender}
            <br />
            {statusTextRender}
        </H1>
    </NotFoundRoot>
}
